<template>
    <v-card elevation="0">
        <v-card-title>
            Создать задачу
        </v-card-title>
        <ShedulerDialog
					:callback="addTask"
					action="Создать"
					:rolesList="rolesList"
                    :showCloseButton="false"
		></ShedulerDialog>
    </v-card>
    
</template>






<script>
import ShedulerDialog from '../dialogs/ShedulerDialog.vue'
import axios from 'axios';
import {getURL} from "../../settings";

    export default {
		name: 'ShedulerAddTask',
        components: { ShedulerDialog },

        data: () => {
		return {
            rolesList: [],

        }







        },

        mounted() {
            this.fetchRoles()


        },

        methods: {
            addTask(task) {
                axios.post(getURL('admin/sheduler/crud'), {...task, action: 'create'},  {withCredentials: true}).then(() => {
                    window.close();
			})
            },

            async fetchRoles(){
                const rolesResponse = await axios.get(getURL('admin/role/list'), {withCredentials: true});
                this.rolesList = rolesResponse.data;
            },
        }

    }
</script>



<style></style>